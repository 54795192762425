import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import i18n from "../../i18n";
import {ABOUT_US, CONTACT, COOKIES, DISCLAIMER, PRICING, PRIVACY_POLICY, TERMS_OF_SERVICES,} from "../../route";

export const Footer: FunctionComponent = () => {
  return (
    <div className="home-footer-bg">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-12 col-md-3">
            <div className="footer-links">
              <h2 className="f18">{i18n.t("Do you have questions? Call or visit us.")}</h2>
              <div className="ft-phone">+(84) 1800 333 555</div>
              <p className="pt-2">Building #3, Shahra-e-Faisal<br />
                Karachi, Pakistan</p>
              <p className="pt-2">info@tazman.com</p>
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="footer-links">
              <h2>{i18n.t("Useful Links")}</h2>
              <ul>
                <li>
                  <Link to={`/${i18n.language}${ABOUT_US}`}>
                    {i18n.t("About Us")}
                  </Link>
                </li>
                {/* <li><Link to={`/${i18n.language}${HOW_IT_WORKS}`}>
                  {i18n.t("How It Works")}
                </Link></li> */}
                <li><Link to={`/${i18n.language}${PRICING}`}>
                  {i18n.t("Pricing")}
                </Link></li>
                <li><Link to={`/${i18n.language}${PRIVACY_POLICY}`}>
                  {i18n.t("Privacy Policy")}
                </Link></li>
                <li><Link to={`/${i18n.language}${TERMS_OF_SERVICES}`}>
                  {i18n.t("Terms & Conditions")}
                </Link></li>
                <li>
                  <Link to={`/${i18n.language}${COOKIES}`}>
                    {i18n.t("Cookies Policy")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-md-2 mt-2" style={{paddingTop: '28px'}}>
            <div className="footer-links">
              <ul>
                <li>
                  <Link to={`/${i18n.language}${DISCLAIMER}`}>
                    {i18n.t("Disclaimer")}
                  </Link>
                </li>
                {/* <li>
                  <Link to={`/${i18n.language}${FAQ}`}>{i18n.t("FAQ's")}</Link>
                </li> */}
                {/* <li>
                  <Link to="/blog">{i18n.t("Blog")}</Link>
                </li> */}
                <li>
                  <Link to={`/${i18n.language}${CONTACT}`}>
                    {i18n.t("Contact")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-sm-3">
            <h2>{i18n.t("Connect With Us")}</h2>
            <p>{i18n.t("Join us on Our Social Platforms for latest news & Updates")}</p>
            <ul className="social-network social-circle">
              <li><a className="icoFacebook" href="#" title="facebook"><i className="fab fa-facebook"></i></a></li>
              <li><a href="#" className="icoTwitter" title="Twitter"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#" className="icoTwitter" title="Twitter"><i className="fab fa-instagram"></i></a></li>
              <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fab fa-linkedin-in"></i></a></li>
            </ul>
          </div>
          <div className="col-12 col-sm-10">
            <div className="footer-bottom">
              Copyright &copy; {new Date().getFullYear()} Tazman.com {i18n.t("All Rights Reserved")} | Powered by Tazman
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
