import React, { FunctionComponent } from "react";
import {TazmanLayout} from "../layout/tazman.layout";
import { Link } from "react-router-dom";
import { HOMEPAGE } from "../../route";
import i18n from "../../i18n";

export const Error404: FunctionComponent = () => (
  <TazmanLayout>
    <div className="inner-header-top">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 top">
            <h1 className="inner-top-heading">
              {i18n.t("Error 404 - Not Found")}
            </h1>
          </div>
        </div>
      </div>
    </div>
   
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="shadow-box" style={{ minHeight: "65vh" }}>
            <div className="p-3">
              {i18n.t("The page you are looking for cannot be found.")} <br />
              {i18n.t("Go back to")}{" "}
              <Link to={HOMEPAGE}>{i18n.t("Homepage")}</Link>.
            </div>
          </div>
        </div>
      </div>
    </div>
  </TazmanLayout>
);
