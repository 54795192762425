import React from 'react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: require('./i18n_en.json')
  },
  ar: {
    translation: require('./i18n_ar.json')
  }
};
const defaultLanguage = 'en';
const selectedLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : defaultLanguage;

export const imgTranslate = (engImg, arImg) => {
  return selectedLanguage && selectedLanguage === 'en' ? engImg : arImg;
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: selectedLanguage,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format) {
        if (format === 'uppercase' && typeof value === 'string') {
          return value.toUpperCase();
        }
        if (format === 'lowercase' && typeof value === 'string') {
          return value.toLowerCase();
        }
        return value;
      },

    }
  });

export default i18n;