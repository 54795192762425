import {GA4React} from "ga-4-react";

const ga4react = new GA4React(
  process.env.REACT_APP_GOOGLE_ANALYTICS_CODE
);
ga4react.initialize().then((ga4) => {
  ga4.pageview('path');
},(err) => {
  console.error(err)
});

export default ga4react;