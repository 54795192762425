import React, {useEffect} from 'react';
import {BrowserRouter, Redirect, Switch, Route} from "react-router-dom";
import {FunctionComponent} from "react";
import {
  ABOUT_US,
  BECOME_COACH, CLUBS,
  COACHES,
  CONTACT,
  COOKIES, DISCLAIMER, FAQ,
  HOMEPAGE,
  HOW_IT_WORKS,
  PRICING,
  PRIVACY_POLICY, TERMS_AND_CONDITIONS, TERMS_OF_SERVICES
} from "./route";
import i18n from "./i18n";
import {GoogleAnalyticsApp} from "./components/GoogleAnalytics/google.analytics";
import {Error404} from "./components/error/error404";

interface AppProps {
  baseUrl: string;
}
export const App: FunctionComponent<AppProps> = (props) => {
  
  const switchTextAlign = () => {
    document.body.style.direction = i18n.language === "en" ? "ltr" : "rtl";
    document.body.setAttribute('dir', document.body.style.direction);
    
    if (i18n.language === 'en') {
      document.body.classList.remove('text-right');
    } else {
      document.body.classList.add('text-right');
    }
  };
  
  useEffect(() => {
    switchTextAlign();
    i18n.on("languageChanged", (lang) => {
      // if the language we switched to is the default language we need to remove the /en from URL
      switchTextAlign();
    });
  }, []);
  
  const Homepage = React.lazy(() => import('./components/homepage/export/homepage'));
  const AboutUs = React.lazy(() => import('./components/about/export/about.us'));
  const AboutCoach = React.lazy(() => import('./components/about/export/about.coach'));
  const Contact = React.lazy(() => import('./components/about/export/contact'));
  const Faq = React.lazy(() => import('./components/about/export/faq'));
  const HowItWorks = React.lazy(() => import('./components/about/export/how.it.works'));
  const Pricing = React.lazy(() => import('./components/about/export/pricing'));
  const Disclaimer = React.lazy(() => import('./components/about/export/disclaimer'));
  const Cookies = React.lazy(() => import('./components/about/export/cookies.policy'));
  const TermsOfServices = React.lazy(() => import('./components/about/export/terms.and.conditions'));
  const Privacy = React.lazy(() => import('./components/about/export/privacy.policy'));
  const FacilityProvider = React.lazy(() => import('./components/about/export/facility-provider'));
  
  const {baseUrl} = props;
  
  return (
    <BrowserRouter basename={baseUrl}>
      {/*<ScrollTop/>*/}
      <React.Suspense fallback={'loading...'}>
        <Switch>
          <Route
            exact
            path={HOMEPAGE}
            render={({match}) =>
              <Redirect to={i18n.language} />
            }
          />
          <Route
            exact
            path={`${HOMEPAGE}:lang`}
            component={Homepage}
          />
          <Route exact path={`/:lang${ABOUT_US}`} component={AboutUs} />
          <Route exact path={`/:lang${COACHES}`} component={AboutCoach} />
          <Route exact path={`/:lang${CONTACT}`} component={Contact} />
          <Route exact path={`/:lang${CLUBS}`} component={FacilityProvider} />
          <Route exact path={`/:lang${FAQ}`} component={Faq} />
          <Route exact path={`/:lang${HOW_IT_WORKS}`} component={HowItWorks} />
          <Route exact path={`/:lang${PRICING}`} component={Pricing} />
          <Route exact path={`/:lang${DISCLAIMER}`} component={Disclaimer} />
          <Route exact path={`/:lang${COOKIES}`} component={Cookies} />
          <Route exact path={`/:lang${TERMS_OF_SERVICES}`} component={TermsOfServices} />
          <Route exact path={`/:lang${PRIVACY_POLICY}`} component={Privacy} />
          <Route exact path={`/:lang${TERMS_AND_CONDITIONS}`} component={TermsOfServices} />
          
          <Route path="*" component={Error404} />
        </Switch>
      </React.Suspense>
      <GoogleAnalyticsApp
        platform="website"
      />
    </BrowserRouter>
  );
};