import React, {FunctionComponent} from 'react';
import i18n from "../../i18n";
import Select, {components} from "react-select";

// const {Option} = Select;

const DropdownIndicator = (
  props: any
) => {
  return (
    <components.DropdownIndicator {...props}>
      {' '}
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = (props: any) => {
  return <>{' '}</>;
};

export const LanguageSelector: FunctionComponent<{ history: any }> = ({
  history,
}) => {
  
  let selectedLanguage = 'en';
  
  if (localStorage.hasOwnProperty('language')) {
    selectedLanguage = localStorage.getItem('language')!;
  } else {
    selectedLanguage = 'en';
  }
  
  function changeLanguage(e: any) {
    const isLang = ["en", "ar"].includes(
      history.location.pathname.substr(1, 2)
    );
    
    selectedLanguage = e;
    
    const lang = selectedLanguage;
    
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    if (isLang) {
      const route = history.location.pathname.substr(3);
      history.push(`/${lang}${route}`);
    }
  }
  
  const languages = [
    {
      label: 'Eng', value: 'en',
    }, {
      label: 'عربى', value: 'ar',
    }
  ];
  
  return (
    <Select
      // defaultValue={[(user && user.locale) ? user.locale : selectedLanguage]}
      defaultValue={languages.filter(item => {
        return item.value === selectedLanguage;
      })}
      onChange={(newValue: any) => {
        changeLanguage(newValue.value)
      }}
      style={{width: '60px'}}
      options={languages}
      components={{
        DropdownIndicator, IndicatorSeparator
      }}
      classNamePrefix="lang-selector"
      className="lang-selector"
      isSearchable={false}
    />
  );
};