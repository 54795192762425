import React, {FunctionComponent, useState} from "react";
import {Col, Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem,} from "reactstrap";
import {Link, NavLink, useHistory} from "react-router-dom";
import {ABOUT_US, AUTH_LOGIN, CLUBS, COACHES, HOMEPAGE} from "../../route";
import i18n from "../../i18n";
import {LanguageSelector} from "../LanguageSelector/language.selector";
import {useMediaQuery} from 'react-responsive'

export const NavbarTop: FunctionComponent = () => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 768px)'
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const history = useHistory();

  const mobileLanguageSelector = (isTabletOrMobileDevice &&
    <div className="lang-before-login">
      <LanguageSelector history={history}/>
    </div>
  );

  return (
    <div className="navmain-cnt">
      <div className="user-nav-top">
        <Container>
          <Navbar light expand="lg">
            <Col xs="6" lg="2" className="d-none d-sm-block d-sm-block">
              <Link to={`/${i18n.language}`}>
                <img
                  alt="logo"
                  className="logo"
                  src={require("tazman-design/images/tazman-logo.webp")}
                />
              </Link>
            </Col>
            <Col xs="6" lg="2" className="d-block d-sm-none">
              <NavbarBrand href={`/${i18n.language}`}>
                <img
                  alt="logo"
                  className="mb-logo"
                  src={require("tazman-design/images/mb-logo.webp")}
                />
              </NavbarBrand>
            </Col>

            <NavbarToggler onClick={toggleOpen}/>

            <Col lg="7" className="col-12 nav-pad-right nav-top">
              <Collapse isOpen={isOpen} className="nav-right" navbar>
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      to={`${HOMEPAGE}${i18n.language}`}
                      className="nav-link"
                      exact
                    >
                      {i18n.t("Home")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={`/${i18n.language}${ABOUT_US}`}
                      className="nav-link"
                      exact
                    >
                      {i18n.t("About Us")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={`/${i18n.language}${COACHES}`}
                      className="nav-link"
                      exact
                    >
                      {i18n.t("Coaches")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={`/${i18n.language}${CLUBS}`}
                      className="nav-link"
                      exact
                    >
                      {i18n.t("Clubs")}
                    </NavLink>
                  </NavItem>
                  {isDesktopOrLaptop &&
                  <div className="before-login">
                    <LanguageSelector history={history}/>
                  </div>}
                </Nav>
              </Collapse>
            </Col>
            <Col lg="3" className="navmb navmb-login">
              <div className={`${!isDesktopOrLaptop ? ('login-left') : ('')}`}>
                {mobileLanguageSelector}
                {isDesktopOrLaptop ? (<a
                    href={`${process.env.REACT_APP_PLAYER_HOST}/${i18n.language}${AUTH_LOGIN}`}
                    className="btn btn-sm btn-orange btn-round"
                  >
                    {i18n.t("Login/Sign up")}
                  </a>)
                  : (
                    <div className="login-mb">
                      <Link to={`/${i18n.language}${AUTH_LOGIN}`}>
                        <i className="fas fa-user-lock fa-1x"/>
                      </Link>
                    </div>
                  )}
              </div>
            </Col>
          </Navbar>
        </Container>
      </div>
    </div>
  );
};
