export const HOMEPAGE = "/";
export const ABOUT_US = "/about-us";
export const COACHES = "/coaches";
export const CONTACT = "/contact";
export const CLUBS = "/clubs";
export const PRICING = "/pricing";
export const FAQ = "/faq";
export const HOW_IT_WORKS = "/how-it-works";
//export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_AND_CONDITIONS = "/terms-of-conditions";
export const SUPPORT = "/support";
export const DISCLAIMER = "/disclaimer";
export const COOKIES = "/cookies-policy";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_SERVICES = "/terms-of-services";

export const VENUE_SEARCH = "/venue/search";
export const VENUE = "/venue/:id";

export const COACH = "/coach/:id";
export const BECOME_COACH = "/become/coach";

export const AUTH_LOGIN = "/auth/login";
export const AUTH_SIGN_UP = "/auth/sign-up";
