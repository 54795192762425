import React, {FunctionComponent, useEffect} from 'react';
import { useHistory } from "react-router-dom";

import ga4react from '../../ga';

export interface GoogleAnalyticsProps {
  platform: string;
}

export const GoogleAnalyticsApp: FunctionComponent<GoogleAnalyticsProps> = (props) => {
  const history = useHistory();
  const location = window.location;
  useEffect(() => {
    if (history.action === 'PUSH') {

      ga4react.gtag('event','page_view', {
        "logged_in": false,
        "platform": props.platform,
        "username": ''
      });
    }
  }, [location.pathname, history.action]);

  return (
    <></>
  )
};