import React, { FunctionComponent } from "react";
import { NavbarTop } from "../navbar/navbar.top";
import { Footer } from "../footer/footer";

export const TazmanLayout: FunctionComponent = (props) => {
  return (
    <>
      <NavbarTop />
      <div style={{minHeight:"1000px"}}>
      {props.children}
      </div>
      <Footer />
    </>
  );
};
